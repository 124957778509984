<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="search()"
        ></SlSearchForm>
      </div>
      <SlTableToolbar>
        <SlButton type="primary" boxShadow="primary" :loading="loading" @click="exportList">导出</SlButton>
      </SlTableToolbar>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        :tableData="tableData"
        :columns="columns"
        :selection="false"
        :operate="false"
        :tooltip="false"
        align="left"
      ></SlTable>
    </SlListView>
  </div>
</template>

<script>
import { exportFileFromRemote, date, errorMessageTip } from '@shared/util'
import CommonUrl from '@api/url.js'
import OemInventoryUrl from '@api/oemInventory/url'
import OemInventoryApi from '@api/oemInventory'

export default {
  name: 'InventoryFlow',
  components: {
  },
  data () {
    return {
      loading: false,
      tableLoading: false,
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {
        operateTimes: [`${date(+new Date(), 'yyyy-MM-dd')} 00:00:00`, `${date(+new Date(), 'yyyy-MM-dd')} 23:59:59`]// 设置默认值
      },
      searchItems: [
        {
          type: 'input',
          label: '底版SKU',
          name: 'skuCode'
        },
        {
          type: 'input',
          label: '来源供应商',
          name: 'consignerName'
        },
        {
          type: 'single-select',
          label: '出库/入库',
          name: 'type',
          data: {
            remoteUrl: CommonUrl.inventoryDictUrl,
            params: { code: 'HISTORY_TYPE' },
            filter: function (item) {
              return item.value !== 2
            }
          }
        },
        {
          type: 'single-select',
          label: '类型',
          name: 'serialType',
          data: {
            remoteUrl: CommonUrl.inventoryDictUrl,
            params: { code: 'SERIAL_TYPE' }
          }
        },
        {
          type: 'date',
          label: '操作时间',
          name: 'operateTimes',
          data: {
            datetype: 'daterange',
            isBlock: true,
            clearable: false
          }
        }
      ],
      columns: [
        {
          prop: 'skuCode',
          label: '底版SKU'
        },
        {
          prop: 'skuImage',
          label: '图片',
          isImg: true,
          data: {
            imgSize: '5rem'
          }
        },
        {
          prop: '',
          label: '商品名称',
          width: '120',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <p>
                {row.productName}
                <p class="color-text--minor">{row.attributes}</p>
              </p>
            )
          }
        },
        {
          prop: 'typeName',
          label: '出库/入库',
          width: '100'
        },
        {
          prop: 'serialTypeName',
          label: '类型'
        },
        {
          prop: 'originTotal',
          label: '期初数量'
        },
        {
          prop: 'changeNum',
          label: '出库/入库数量'
        },
        {
          prop: 'currentTotal',
          label: '期末数量'
        },
        {
          prop: 'consignerName',
          label: '来源供应商'
        },
        {
          prop: 'createTime',
          label: '操作时间',
          width: '150'
        }
      ]
    }
  },
  watch: {
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemInventoryApi.inventorySerialPage(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.getQuery(),
        pageIndex,
        pageSize
      }
    },
    getQuery () {
      let { operateTimes = [], ...orther } = this.query
      return {
        ...orther,
        operateTimeStart: operateTimes && operateTimes[0] ? operateTimes[0] : '',
        operateTimeEnd: operateTimes && operateTimes[1] ? operateTimes[1] : ''
      }
    },
    search () {
      if (!this.query.operateTimes) {
        errorMessageTip('操作时间条件不能为空')
        return
      }
      this.gotoPage(this.page.pageSize)
    },
    exportList () {
      if (!this.query.operateTimes) {
        errorMessageTip('操作时间条件不能为空')
        return
      }
      const { operateTimes = [] } = this.query
      const subs = Number(new Date(operateTimes[1])) - Number(new Date(operateTimes[0]))
      const diffs = subs / 24 / 60 / 60 / 1000
      if (diffs > 31) {
        errorMessageTip('只能导出一个月以内的库存流水！')
        return
      }
      exportFileFromRemote({
        url: OemInventoryUrl.inventorySerialExport,
        name: `库存流水_${date(+new Date(), 'yyyy-MM-dd')}.xlsx`,
        params: this.getQuery(),
        beforeLoad: () => {
          this.loading = true
          this.$store.dispatch('OPEN_LOADING', { isCount: false, loadingText: '导出中' })
        },
        afterLoad: () => {
          this.loading = false
          this.$store.dispatch('CLOSE_LOADING')
        },
        successFn: () => { },
        errorFn: () => {
          errorMessageTip('导出失败,请重试')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
